<template>
  <div>
    
    <el-row justify="space-between">
      <el-col :span="4">
      </el-col>
      <el-col :span="4">
      </el-col>
      <el-col :span="8">
<!--        <el-input placeholder="Cerca" v-model="query.s" @input="" @clear="clearInput" clearable></el-input>-->
        <el-input placeholder="Cerca" v-model="searchText" @clear="clearInput" clearable></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="logsList">
        <el-table
            :data="logsList"
            stripe 
            border
            style="width: 100%"
        >
          <el-table-column :min-width="3" prop="id" label="ID"></el-table-column>
          <el-table-column :min-width="12" prop="type" label="Tipo"></el-table-column>
          <el-table-column :min-width="10" prop="datetime" label="Data segnalazione">
             <template #default="scope">
              {{ formatDate(scope.row.datetime)}}
            </template>
          </el-table-column>
          <el-table-column :min-width="75" prop="description" label="Descrizione" ></el-table-column>
          <!-- <el-table-column prop="data" label="Data">
            </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-pagination v-if="logsList"
          background
          layout="prev, pager, next, sizes"
          :total="totalLogs"
          :page-size="query.limit"
          v-model:currentPage="currentPage"
          :page-sizes="pagination.pageSizes"
          @size-change="sizeChange"
          @current-change="currentChange"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DateMixin from "../../mixins/DateMixin";
import IconsMixin from "../../mixins/IconsMixin";

export default ({
  name: "Logs",
  mixins: [IconsMixin, DateMixin],
  data() {
    return {
      newUserFields: [],
      currentPage: 1,
      pagination: {
        start: 1,
        pageSizes: [2, 5, 10, 20, 50]
      },
      query: {
        limit: 20,
        offset: 0,
        s: "",
        active: true
      },
      inputTimer: 0,
      searchText: ""
    }
  },
  computed: {
    ...mapGetters(["logsList", "totalLogs"])
  },
  methods: {
    ...mapActions(["fetchAllLogs"]),
    async sizeChange(val) {
      this.query.limit = val;
      //this.query.offset = (this.currentPage - 1) * this.query.limit;
      this.query.offset=0;
      this.currentPage=1;
      await this.fetchAllLogs(this.query);
    },
    async currentChange(val) {
      this.currentPage = val;
      this.query.offset = (val - 1) * this.query.limit;
      await this.fetchAllLogs(this.query);
    },
    inputSearch(){
      this.query.s = this.searchText
      this.fetchAllLogs(this.query);
    },
    clearInput(){
      this.query.s = ""
      this.fetchAllLogs(this.query);
    },
    async loadList(){
      //console.log(this.query);
      await this.fetchAllLogs(this.query);
    }

  },
  async created() {
    await this.fetchAllLogs();
    //await this.fetchAllLogs(this.query);
  },
  watch: {
    searchText: function () {
      if (this.inputTimer) {
        clearTimeout(this.inputTimer)
      }
      if (this.searchText.length > 2) {
        this.inputTimer = setTimeout(() => this.inputSearch(), 1000)
      }
    }
  },
})
</script>

<style scoped>

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}

</style>
